import React, { Component } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import GamePage from "../GamePage/GamePage";
import gameBanner from "../../img/game-page/Baccarat/banner.jpg";
import gameBannerMobile from "../../img/game-page/Baccarat/bannerMobile.jpg";

import desktopIcon from "../../img/game-page/Baccarat/laptop.svg";
import tabletIcon from "../../img/game-page/Baccarat/tablet.svg";
import mobileIcon from "../../img/game-page/Baccarat/phone.svg";
import deviceDesktop from "../../img/game-page/Baccarat/device-desktop.png";
import deviceTablet from "../../img/game-page/Baccarat/device-tablet.png";
import deviceMobile from "../../img/game-page/Baccarat/device-mobile.png";

import slideDesktop1 from "../../img/game-page/Baccarat/gallery-desktop/slide-1.png";
import slideDesktop2 from "../../img/game-page/Baccarat/gallery-desktop/slide-2.png";
import slideDesktop3 from "../../img/game-page/Baccarat/gallery-desktop/slide-3.png";

import slideTablet1 from "../../img/game-page/Baccarat/gallery-tablet/slide-1.png";
import slideTablet2 from "../../img/game-page/Baccarat/gallery-tablet/slide-2.png";
import slideTablet3 from "../../img/game-page/Baccarat/gallery-tablet/slide-3.png";

import slideMobile1 from "../../img/game-page/Baccarat/gallery-mobile/slide-1.png";
import slideMobile2 from "../../img/game-page/Baccarat/gallery-mobile/slide-2.png";
import slideMobile3 from "../../img/game-page/Baccarat/gallery-mobile/slide-3.png";

import feature1 from "../../img/game-page/Baccarat/features/feature1.svg";
import feature2 from "../../img/game-page/Baccarat/features/feature2.svg";
import feature3 from "../../img/game-page/Baccarat/features/feature3.svg";

import partner1 from "../../img/game-partners/BETB2B.svg";
import partner3 from "../../img/game-partners/1win.svg";
import partner4 from "../../img/game-partners/pronet.svg";
import partner5 from "../../img/game-partners/infinGame.svg";
import partner6 from "../../img/game-partners/betconstruct.svg";
import partner7 from "../../img/game-partners/softswiss.svg";
import partner8 from "../../img/game-partners/parimatch.svg";
import partner9 from "../../img/game-partners/softgaming.svg";
import partner11 from "../../img/game-partners/top-play-gaming.svg";

import rules1 from "../../img/rules/baccarat/main-bets.png";
import rules2 from "../../img/rules/baccarat/no-comission.png";
import rules3 from "../../img/rules/baccarat/side-bets.png";
import rules4 from "../../img/rules/baccarat/bead-road.png";
import rules5 from "../../img/rules/baccarat/big-eye-boy.png";
import rules6 from "../../img/rules/baccarat/small-road.png";
import rules7 from "../../img/rules/baccarat/cockroach-pig.png";
import rules8 from "../../img/rules/baccarat/prediction-buttons.png";
import rules9 from "../../img/rules/baccarat/summary-statistics.png";
import rules10 from "../../img/rules/baccarat/draw-history.png";
import rules11 from "../../img/rules/baccarat/big-road.png"

import beadRoadPlayerWin from "../../img/rules/baccarat/bead-road-player-win.svg";
import beadRoadBankerWin from "../../img/rules/baccarat/bead-road-banker-win.svg";
import beadRoadTie from "../../img/rules/baccarat/bead-road-tie.svg";
import beadRoadPlayerPair from "../../img/rules/baccarat/bead-road-player-pair.svg";
import beadRoadBankerPair from "../../img/rules/baccarat/bead-road-banker-pair.svg";
import bigRoadPlayerWin from "../../img/rules/baccarat/big-road-player-win.svg";
import bigRoadBankerWin from "../../img/rules/baccarat/big-road-banker-win.svg";
import bigRoadTie from "../../img/rules/baccarat/big-road-tie.svg";
import bigRoadPlayerPair from "../../img/rules/baccarat/big-road-player-pair.svg";
import bigRoadBankerPair from "../../img/rules/baccarat/big-road-banker-pair.svg";



import { loadResources } from "../../util";
import logo from "../../img/logo-header-ler.svg";
import gilroyBold from "../../fonts/Gilroy/Gilroy-Bold.woff2";
import PTRootUIMedium from "../../fonts/PTRootUI/PTRootUI-Medium.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const partners = [
    {
        title: "1win",
        date: "2024",
        logo: partner3,
    },
    {
        title: "SoftSwiss",
        date: "2024",
        logo: partner7,
    },
    {
        title: "PariMatch",
        date: "2024",
        logo: partner8,
    },
    {
        title: "SoftGamings",
        date: "2024",
        logo: partner9,
    },
    {
        title: "BetB2B",
        date: "2024",
        logo: partner1,
    },
    {
        title: "Betconstruct",
        date: "2024",
        logo: partner6,
    },
    {
        title: "ProNetGaming",
        date: "2024",
        logo: partner4,
    },
    {
        title: "Infingame",
        date: "2024",
        logo: partner5,
    },
    {
        title: "TopPlayGaming",
        date: "2024",
        logo: partner11,
    },
];

const rules = (
    <div className={'baccarat-rules'}>
        <h1>Game</h1>
        <p>
            Baccarat is an ancient card game that remains one of the most popular games in casinos worldwide. Despite its simple rules, Baccarat is a very dynamic game that keeps you on the edge of your seat until the very end!
        </p>
        <p>The aim of the game is to guess which side (Hand), the Player or the Banker, will have a point total closer to 9. If the final point total on both hands is the same, the game ends in a Tie.</p>
        <p>At that, the point total on each side cannot exceed 9. If the point total on any hand is equal to or greater than 10, you should subtract 10 from it, and the remaining value will be the final score of that hand. For example, if the point total on a hand is 15, only 5 points will be counted (15-10=5).</p>
        <h1>Card values</h1>
        <p>
            8 standard decks of 52 cards each are used for the game. The cards have
            the following nominal values:
        </p>
        <ul>
            <li>An ace is worth 1 point;</li>
            <li>The score of the cards from 2 to 9 is equal to their nominal value</li>
            <li>
                Tens and Picture-Cards (a Jack, a Queen, and a King) bring 0 points each.
            </li>
        </ul>
        <p>
            At that, only the nominal values of cards are taken into account. The suits of cards (spades, clubs, hearts,
            or diamonds) do not matter.
        </p>
        <h1>Drawing and the Third Card Rule</h1>
        <p>
            At the beginning of the draw, the dealer deals 2 cards to each of the Player and the Banker (one card at a
            time, starting with the Player).
        </p>
        <p>
            This step may end the draw (Small Game), but only in the following cases:
        </p>
        <ol>
            <li>If either side (the Player or the Banker) is dealt a hand totaling 8 or 9 points with their initial two
                cards (the Natural Hand).
            </li>
            <li>If one side has a total of 6 points from the initial cards and the other side has a total of 7 points.
            </li>
            <li>
                If the point total on both hands is the same (Tie) and is equal to 6, 7, 8, or 9 points.
            </li>
        </ol>
        <p>
            In all other cases, an additional card (for the Player and/or the Banker) is required to determine the
            winner. This additional card is dealt according to the Third Card Rule:
        </p>
        <p>
            For the Player:
        </p>
        <table className="baccarat-rules__two-column-table">
            <tbody>
                <tr>
                    <th>If the point total for the Player’s first 2 cards is:</th>
                    <th></th>
                </tr>
                <tr>
                    <th>0, 1, 2, 3, 4 or 5</th>
                    <th>The Player is dealt an additional card</th>
                </tr>
                <tr>
                    <th>6 or 7</th>
                    <th>No additional card is dealt to the Player</th>
                </tr>
                <tr>
                    <th>8 or 9</th>
                    <th>Neither side is dealt an additional card</th>
                </tr>
            </tbody>
        </table>
        <p>
            For the banker:
        </p>
        <div className="baccarat-rules__big-table">
            <table>
                <tbody>
                    <tr>
                        <th style={{ width: '15%', textAlign: 'left' }} rowSpan={2}>If the point total for the Banker’s first
                            2 cards is:
                        </th>
                        <th colSpan={11}>Value of the Player’s third card:</th>
                    </tr>
                    <tr>
                        <th
                            style={{ width: '15%' }}
                        >There is no third card
                        </th>
                        <td>0</td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                        <td>7</td>
                        <td>8</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>0</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>S</td>
                        <td>D</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>D</td>
                        <td>S</td>
                        <td>S</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>D</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>D</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>D</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p>
            D – the Banker receives an additional card.
        </p>
        <p>
            S – the Banker does not receive an additional card.
        </p>
        <p>
            If the point total for the first two cards in the Player’s hand is 6 or 7 points and the point total for the
            first two cards in the Banker’s hand is 0, 1, 2, 3, 4, or 5 points, then the Banker also receives an
            additional card.
        </p>
        <p>
            The draw ends in the Player’s win if the final score of their hand is closer to 9 points than the final
            score of the Banker’s hand. On the contrary, if the final score of the Banker’s hand is closer to 9 points
            than the Player’s final score, the draw ends in Banker’s win.
        </p>
        <p>
            If the final point total of both hands is the same, the draw ends in a Tie.
        </p>
        <h1>Main Bets</h1>
        <p>
            There are 3 main bets in Baccarat that correspond to each of the possible draw outcomes:
        </p>
        <ul>
            <li>On the Player’s hand.<br />This bet wins if the draw ends in the Player’s victory.<br />The payout for the
                bet on the Player’s hand is 1:1.
            </li>
            <li>On the Banker’s hand.<br />This bet wins if the draw ends in the Banker’s victory. <br />The payout for
                the bet on the Banker’s hand is 0.95:1.
            </li>
            <li>On a Tie.<br />This bet wins if the draw ends in a Tie. <br />The payout for the bet on a Tie is 8:1</li>
        </ul>
        <img className='baccarat-rules__img-fixed' src={rules1} />
        <p>
            Please note the following:
        </p>
        <ol>
            <li>A Push occurs if the draw ends in a Tie, bets on the Player or the Banker are not lost but refunded.
            </li>
            <li>You can only bet on one hand in a single draw – either the Player’s or the Banker’s hand. Simultaneous
                betting on both hands is prohibited.
            </li>
        </ol>
        <h1>No Commission Mode</h1>
        <p>The No Commission mode is a game option. When this mode is enabled, no commission is charged for winnings on
            Banker bets. In this mode, the payout for winnings on Banker bets will be 1:1, except when the Banker wins
            with 6 points. In this case, the payout for winning will be 0.5:1.</p>
        <div className='baccarat-rules__img-fixed'><img src={rules2} /></div>
        <p>There is an additional bet (the Lucky Six bet) among the playing options in the No Commission mode. This bet
            wins if the Banker wins with exactly 6 points. It serves as insurance against a halved payout for the
            Banker’s win. The payout for the Lucky Six bet is 15:1.</p>
        <p>Thus, there are 4 main bets in the No Commission mode:</p>
        <ul>
            <li>On the Player’s hand.<br />This bet wins if the draw ends in the Player’s victory.<br />The payout for the
                bet on the Player’s hand is 1:1.
            </li>
            <li>On the Banker’s hand. <br />This bet wins if the draw ends in the Banker’s victory.<br />The payout for
                the bet on the Banker’s hand (with any score other than 6 points) is 1:1.<br />The payout for the bet on
                the Banker’s hand (with a score of 6 points) is 0.5:1.
            </li>
            <li>Lucky Six.<br />This bet wins if the draw ends in the Banker’s victory with exactly 6 points. <br />The
                payout for the Lucky Six bet is 15:
            </li>
            <li>On a Tie. <br />This bet wins if the draw ends in a Tie.<br />The payout for the bet on a Tie is 8:1.</li>
        </ul>
        <p>Please note the following:</p>
        <ol>
            <li>A Push occurs if the draw ends in a Tie, bets on the Player or the Banker are not lost but refunded.
            </li>
            <li>You can only bet on one hand in a single draw – either the Player’s or the Banker’s hand. Simultaneous
                betting on both hands is prohibited.
            </li>
            <li>The Lucky Six bet becomes unavailable after 44 draws from installing the Shoe containing a new deck.
            </li>
        </ol>
        <h1>Side Bets</h1>
        <p>Along with your main bets, you can also place side bets: the Player/Banker Pair and the Player/Banker Bonus.
            These side bets are available whether or not you have placed your main bets.</p>
        <img src={rules3} />
        <p style={{ fontWeight: 700 }}>Player Pair and Banker Pair:</p>
        <p>The Player Pair or the Banker Pair bet wins if two initial cards of the Player or the Banker, respectively,
            form a pair. That is, the initial hand consists of 2 cards of the same value. For example, two Kings, two
            Fives, two Aces.</p>
        <p>In this case, only the value of the cards is considered, the suits of the cards do not matter.</p>
        <p>The payout for the Player Pair bet and the Banker Pair bet is 11:1.</p>
        <p>Please note that the Player Pair bet and the Banker Pair bet become unavailable after 60 draws from
            installing a Shoe containing a new deck.</p>
        <p style={{ fontWeight: 700 }}>Player Bonus and Banker Bonus:</p>
        <p>Player Bonus and Banker Bonus bets are classic side bets in Baccarat, commonly found in both online and
            offline casinos.</p>
        <p>The Player Bonus bet or the Banker Bonus bet wins if the Player or the Banker, respectively, wins with a
            Natural Hand (no additional cards) or with additional cards and a margin of at least 4 points. At that, the
            payout for winning of this bet may vary depending on the specific outcome of the draw.</p>
        <table className="baccarat-rules__two-column-table">
            <tbody>
                <tr>
                    <th>Outcome of the draw:</th>
                    <th>Payout:</th>
                </tr>
                <tr>
                    <th>Unnatural win by 9 points</th>
                    <th>30:1</th>
                </tr>
                <tr>
                    <th>Unnatural win by 8 points</th>
                    <th>10:1</th>
                </tr>
                <tr>
                    <th>Unnatural win by 7 points</th>
                    <th>6:1</th>
                </tr>
                <tr>
                    <th>Unnatural win by 6 points</th>
                    <th>4:1</th>
                </tr>
                <tr>
                    <th>Unnatural win by 5 points</th>
                    <th>2:1</th>
                </tr>
                <tr>
                    <th>Unnatural win by 4 points</th>
                    <th>1:1</th>
                </tr>
                <tr>
                    <th>Natural win (no additional cards)</th>
                    <th>1:1</th>
                </tr>
                <tr>
                    <th>Natural tie (no additional cards)</th>
                    <th>Push (bet refund)</th>
                </tr>
            </tbody>
        </table>
        <p>Please note that the Player Bonus bet and the Banker Bonus bet become unavailable after 44 draws from
            installing a Shoe containing a new deck.</p>
        <h1>Return to Player</h1>
        <p>The theoretical Return to Player (RTP) in Classic mode amounts to:</p>
        <table className="baccarat-rules__two-column-table">
            <tbody>
                <tr>
                    <th>Bet:</th>
                    <th>RTP:</th>
                </tr>
                <tr>
                    <th>Bet on Player</th>
                    <th>98.76%</th>
                </tr>
                <tr>
                    <th>Bet on Banker</th>
                    <th>98.94%</th>
                </tr>
                <tr>
                    <th>Bet on Tie</th>
                    <th>85.64%</th>
                </tr>

            </tbody>
        </table>
        <p>The theoretical Return to Player (RTP) in No Commission mode amounts to:</p>
        <table className="baccarat-rules__two-column-table">
            <tbody>
                <tr>
                    <th>Bet:</th>
                    <th>RTP:</th>
                </tr>
                <tr>
                    <th>Bet on Player</th>
                    <th>98.76%</th>
                </tr>
                <tr>
                    <th>Bet on Banker</th>
                    <th>98.54%</th>
                </tr>
                <tr>
                    <th>Bet on Tie</th>
                    <th>85.64%</th>
                </tr>
                <tr>
                    <th>Lucky 6</th>
                    <th>86.18%</th>
                </tr>

            </tbody>
        </table>
        <p>The theoretical Return to Player (RTP) for side bets amounts to:</p>
        <table className="baccarat-rules__two-column-table">
            <tbody>
                <tr>
                    <th>Bet:</th>
                    <th>RTP:</th>
                </tr>
                <tr>
                    <th>P Pair</th>
                    <th>89.64%</th>
                </tr>
                <tr>
                    <th>B Pair</th>
                    <th>89.64%</th>
                </tr>
                <tr>
                    <th>P Bonus</th>
                    <th>97.35%</th>
                </tr>
                <tr>
                    <th>B Bonus</th>
                    <th>90.63%</th>
                </tr>

            </tbody>
        </table>
        <h1>Scoreboards and Statistics</h1>
        <p>Scoreboards (or Roads) are special boards used to record statistics for the currently installed deck.
            Scoreboards conveniently display information about the outcomes of the draws played, allowing you to easily
            understand deck trends and attempt to predict the outcome of the next draw. There are several types of
            Roads: the Main Roads (Bead Road and Big Road) and Derivative Roads (Big Eye Boy, Small Road, and Cockroach
            Pig).</p>
        <p>Each road only displays outcomes for the deck currently installed in the Shoe. That is, after a deck is tied
            on, all the tables are reset. Their filling up is resumed after the Shoe Change.</p>
        <p style={{ fontWeight: 700 }}>Main Roads:</p>
        <p>The Main Roads start to fill up after the first draw is played. Each mark of the Main Roads corresponds to
            the outcome of the game.</p>
        <p style={{ fontWeight: 700 }}>Bead Roads:</p>
        <img className='baccarat-rules__img-fixed' src={rules4} />
        <p>In the Bead Road, the first mark is placed in the top left corner of the table, and all the subsequent marks
            are inserted without any skips from top to bottom, from left to right.</p>
        <p>Each mark corresponds to one of the possible outcomes of the draw:</p>
        <div className={'baccarat-rules__row-with-img'}><img src={beadRoadPlayerWin} />Player Win;</div>
        <div className={'baccarat-rules__row-with-img'}><img src={beadRoadBankerWin} />Banker Win;</div>
        <div className={'baccarat-rules__row-with-img'}><img src={beadRoadTie} />Tie;</div>
        <div className={'baccarat-rules__row-with-img'}><img src={beadRoadPlayerPair} />Player Pair (additional information);
        </div>
        <div className={'baccarat-rules__row-with-img'}><img src={beadRoadBankerPair} />Banker Pair (additional information).
        </div>
        <p>Clicking on the Bead Road table changes the scope of information displayed in it: The hand designation
            changes to the score, with which the game ended (the score of the winner or the tie score).</p>
        <p style={{ fontWeight: 700 }}>Big Road:</p>
        <img src={rules11} />
        <p>The Big Road is another option for visualizing the underlying statistics. In the Big Road, similar to the
            Bead Road, the first mark is placed at the end of the first draw with a new deck, starting in the top left
            corner of the table. The blue sign marks the Player’s wins, whereas the red sign marks the Banker’s wins.
            However, each transition of wins from one side to the other starts a new column.</p>
        <p>In addition, a Tie dos not occupy a separate cell on the Big Road. Instead, it is indicated by a line that
            crosses out the previous draw outcome. The column continues after a Tie without breaking, as a Tie is not
            considered to be a transfer of winnings to the other hand. In case several consecutive draws end in a Tie, a
            number indicating the count of consecutive Ties is placed on top of the line. If the very first draw for the
            deck ends in a Tie, a line is placed in the first square. Around this line, the mark of the first win of any
            of the hands will appear.</p>
        <p>In addition to the main outcomes (wins of the Player or the Banker, and a Tie), the Big Road also tracks
            pairs of the Player and the Banker:</p>
        <div className={'baccarat-rules__row-with-img'}><img src={bigRoadPlayerWin} />Player Win;</div>
        <div className={'baccarat-rules__row-with-img'}><img src={bigRoadBankerWin} />Banker Win;</div>
        <div className={'baccarat-rules__row-with-img'}><img src={bigRoadTie} />Tie;</div>
        <div className={'baccarat-rules__row-with-img'}><img src={bigRoadPlayerPair} />Player Pair (additional information);
        </div>
        <div className={'baccarat-rules__row-with-img'}><img src={bigRoadBankerPair} />Banker Pair (additional information).
        </div>
        <p style={{ fontWeight: 700 }}>Derivative Roads:</p>
        <p>In addition to the Main Roads, there are three Derivative Roads in Baccarat: Big Eye Boy, Small Road, and Cockroach Pig. Unlike the Main Roads, the marks on these Derivative Roads do not indicate the outcome of the draw but rather the presence (Red Color) or absence (Blue Color) of a repeating pattern. Thus, the colors of the marks on the Derivative Roads do not correspond to the colors of the Player’s or the Banker’s hands. No other marks are placed on the Derivative Roads.</p>
        <p>The Derivative Roads (Big Eye Boy, Small Road, and Cockroach Pig) serve for monitoring of repeating patterns within the same deck but at different playing distances. These Derivative Roads can help you predict game trends and results of future draws more accurately.</p>
        <p style={{ fontWeight: 700 }}>Big Eye Boy:</p>
        <img src={rules5} />
        <p>The Big Eye Boy road searches for repeating patterns at the shortest distance. The marks in this table appear as circles in red (indicating order) or blue (indicating chaos) color.</p>
        <p style={{ fontWeight: 700 }}>Small Road:</p>
        <img src={rules6} />
        <p>The Small Road serves for monitoring of repeating patterns over a medium distance. The marks in this table appear as filled circles in red (indicating order) or blue (indicating chaos) color.</p>
        <p style={{ fontWeight: 700 }}>Cockroach Pig:</p>
        <img src={rules7} />
        <p>The Cockroach Pig road serves for monitoring of repeating patterns over the longest distance. The marks in this table appear as slashes in red (indicating order) or blue (indicating chaos) color.</p>
        <p>Initially, in the interface of the game, the Derivative Roads are shown in a collapsed form, so you can view deck trends across all distances. However, by clicking on each Derivative Road, you can zoom in to view it in detail. You can also return it to its collapsed state just as easily.</p>
        <p style={{ fontWeight: 700 }}>Predicting Results:</p>
        <p>There are 2 buttons for predicting wins of the Player and the Banker. The are located above the Scoreboards. Clicking each button shows the marks that will appear on all roads if the next draw results in the Player’s win or the Banker’s win, respectively:</p>
        <div className='baccarat-rules__img-fixed'><img src={rules8} /></div>
        <p>These win prediction buttons help visualize how the values in the Scoreboards will change, making it easier to predict deck trends.</p>
        <p style={{ fontWeight: 700 }}>Summary Statistics:</p>
        <p>In addition to Scoreboards, the game interface displays Summary Statistics for the outcomes of the currently installed deck. It shows the total number of the Player’s wins, Banker’s wins, Ties, and Player/Banker pairs.</p>
        <div className='baccarat-rules__img-fixed'><img src={rules9}/></div>
        <h1>History of Draws</h1>
        <p>The results of previous draws with your participation are available in the “History” tab. There is a list of the draws you’ve bet on in this tab. It also contains a detailed history of each draw, including game outcomes and any actions you’ve taken.</p>
        <p>The tab “History of Draws” opens by clicking the appropriate button in the game interface. To expand the detailed history of an individual draw, click on the draw of interest in the list:</p>
        <img src={rules10} />
        <h1>Card Shuffling</h1>
        <p>A combined deck consisting of 8 standard playing decks with 52 cards in each is used for the game. The dealer places a cutting card close to the last card of this combined deck. After, the entire deck is placed in the Shoe. Rounds are played one after another with one and the same deck without shuffling, until the dealer draws a cutting card from the Shoe. The appearance of this cutting card means the Shoe Change, where the combined deck is replaced with a new one at the end of this particular draw.</p>
        <p>After the Shoe Change in Baccarat, the following procedure takes place: The dealer draws the first card from the new combined deck and shows it to the players. After, the dealer burns (i.e. removes into the closed) several cards from the deck. The number of burned cards depends on the nominal value of the card that the dealer has previously opened:</p>
        <ul>
            <li>If an Ace is opened, 1 card is burned;</li>
            <li>If a Ten or a Picture-Card (a Jack, a Queen, or a King) is opened, 10 cards are burned;</li>
            <li>If a card with a numerical value (from 2 to 9) is opened, a number of cards equal to its nominal value is burned</li>
        </ul>
        <p>A new draw begins following the completion of this procedure</p>
        <h1>Connectivity Issues</h1>
        <p>If a player is disconnected from the game due to any connectivity issues on their end, the bets they placed are considered valid and participate in the draw in the absence of the player.</p>
        <p>Following the connection restoration, the player can view the results of the respective draw in the “History” tab.</p>
        <h1>Game Issues</h1>
        <p>If a player is disconnected from the game due to any connectivity issues on their end, the bets they placed are considered valid and participate in the draw in the absence of the player.</p>
        <p>Following the connection restoration, the player can view the results of the respective draw in the “History” tab.</p>
        <h1>Good Luck!</h1>


    </div>
);

class GamePageBaccarat extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        loadResources([
            ["image", logo],
            ["image", gameBanner],
            ["image", gameBannerMobile],
            ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
            [
                "font",
                PTRootUIMedium,
                { name: "PT Root UI", style: "normal", weight: 500 },
            ],
        ]).then(() => {
            this.props.dispatch({
                type: "set-resources-loaded",
                payload: { loaded: true },
            });
        });
    }

    render() {
        return (
            <>
                <LoaderLogo isLoaded={this.props.loadedResources.loaded} />
                <GamePage
                    banner={gameBanner}
                    bannerMobile={gameBannerMobile}
                    theme="game_bc"
                    name="speed Baccarat"
                    text="We are welcoming you to our new luxury studio with a classic speed Baccarat game and stylish atmosphere. Сharismatic game presenters, side bets and two game modes are waiting for you!"
                    desktopIcon={desktopIcon}
                    tabletIcon={tabletIcon}
                    mobileIcon={mobileIcon}
                    deviceDesktop={deviceDesktop}
                    deviceTablet={deviceTablet}
                    deviceMobile={deviceMobile}
                    slidesDesktop={[slideDesktop1, slideDesktop2, slideDesktop3]}
                    slidesTablet={[slideTablet1, slideTablet2, slideTablet3]}
                    slidesMobile={[slideMobile1, slideMobile2, slideMobile3]}
                    features={[
                        {
                            icon: feature1,
                            text: "Classic or No Commission",
                        },
                        {
                            icon: feature2,
                            text: "Lucky Six",
                        },
                        {
                            icon: feature3,
                            text: "Side Bets",
                        },
                    ]}
                    rules={rules}
                    partners={partners}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.language,
    loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(GamePageBaccarat);
